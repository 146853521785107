<template>
  <div v-frag>
    <v-card>
      <v-card-title>
        <v-icon color="primary" class="mr-4">mdi-truck-fast-outline</v-icon>
        <span class="subtitle-1">{{ $t('shippingAddresses') }}</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-6">
        <v-row justify="end" class="ma-0">
          <v-col cols="12" sm="6" class="pa-0">
            <v-text-field
              v-model="search"
              :aria-label="$t('search')"
              :label="$t('search')"
              filled
              prepend-inner-icon="mdi-magnify"
              persistent-hint
              clearable
              :hint="$t('inputMessages.searchHint')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="addresses"
          :search="search"
          :loading="loadingData"
          :loading-text="$t('loadingText')"
          no-data-text="Nem található szállítási cím"
          :no-results-text="`A keresett ${search} kifejezés nem található`"
        >
          <template v-slot:item.btn="{item}">
            <v-btn text color="primary" @click="openAddressModal(item)">
              {{ $t('button.view') }}
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <!--<v-card-actions class="px-6 pb-4">
        <v-spacer></v-spacer>
        <v-btn class="primary" @click="openAddressModal(addressPrototype, true)">
          {{ $t('button.newAddress') }}
        </v-btn>
      </v-card-actions>-->
    </v-card>
    <v-dialog v-model="addressModal.visible" max-width="800" persistent>
      <address-details :address.sync="addressModal.addressItem" v-on:close-dialog="closeAddressModal" :is-editable="addressModal.isEditable"></address-details>
    </v-dialog>
    <!-- ÚJ CÍM HOZZÁADÁSA START -->
    <!--<v-dialog v-model="deliveryInAddressModal" max-width="800" persistent>
      <v-card tile>
        <v-card-title class="third">
          <v-icon color="white" class="mr-4">mdi-truck-check-outline</v-icon>
          <span class="white--text">{{ $t('shippingAddress') }}</span>
          <v-spacer></v-spacer>
          <v-icon @click="deliveryInAddressModal = false" color="white">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <v-form v-model="deliveryInAddressForm">
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete v-model="addressPrototype.zipCode"
                                :items="selectFieldOptions.postalCodes"
                                item-text="address.postalCode"
                                item-value="address.postalCode"
                                :search-input.sync="postalCodeSearch"
                                :label="$t('inputLabels.postalCode')"
                                :rules="inputRules.phoneRules"
                                :hint="$t('inputMessages.requiredField')"
                                :no-data-text="$t('inputMessages.noDataText')"
                                persistent-hint
                                filled
                                maxlength="4"
                                required
                                @change="onPostalCodeSelect"
                                @click:clear="clearAllAddressInputs"
                                clearable>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete v-model="addressPrototype.city"
                                :items="selectFieldOptions.cities"
                                item-value="address.city"
                                item-text="address.city"
                                :search-input.sync="citySearch"
                                :label="$t('inputLabels.city')"
                                :rules="inputRules.generalRules"
                                :hint="$t('inputMessages.requiredField')"
                                :no-data-text="$t('inputMessages.noDataText')"
                                persistent-hint
                                filled
                                required
                                clearable
                                @click:clear="clearAllAddressInputs">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="8">
                <v-combobox v-model="streetIdeiglenes"
                            :items="selectFieldOptions.streets"
                            item-value="address.street"
                            item-text="address.street"
                            :search-input.sync="streetSearch"
                            :label="$t('inputLabels.publicPlaceName')"
                            :rules="inputRules.generalRules"
                            :hint="$t('inputMessages.requiredField')"
                            :no-data-text="$t('inputMessages.noDataText')"
                            persistent-hint
                            filled
                            required
                            @change="onPublicPlaceSelect"
                            clearable>
                </v-combobox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field v-model="addressPrototype.houseNumber"
                              :label="$t('inputLabels.streetNumber')"
                              :rules="inputRules.generalRules"
                              :hint="$t('inputMessages.requiredField')"
                              :no-data-text="$t('inputMessages.noDataText')"
                              persistent-hint
                              filled
                              required
                              clearable>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea v-model="addressPrototype.otherAddressInfo"
                            :label="$t('otherDescription')"
                            :hint="$t('inputMessages.optionalField')"
                            persistent-hint
                            clearable
                            filled
                            clear-icon="mdi-close"
                            maxlength="50"
                            counter="50">
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <v-spacer></v-spacer>
          <v-btn text @click="deliveryInAddressModal = false">
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn class="primary" @click="saveDeliveryInAddressData" :disabled="!deliveryInAddressForm">
            {{ $t('button.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
    <!-- ÚJ CÍM HOZZÁADÁSA END -->
  </div>
</template>

<script>
import AddressDetails from './AddressDetails.vue';
import {HEREGETCITYBYPOSTALCODE, HEREGETPOSTALCODESBYCOUNTRY, HEREGETSTREETBYPOSTALCODE} from '../../utils/hereAPI';

export default {
  name: 'ShippingInformation',
  components: {
    AddressDetails
  },
  props: {
    //
  },
  async beforeMount() {
    await this.loadTableData();
    if (this.addresses.length > 0) {
      this.addresses = this.deleteIdenticalAddresses(this.addresses);
    }
    // setTimeout(this.loadTableData, 1500);
  },
  data() {
    return {
      search: null,
      headers: [
        { text: this.$t('postalCode'), value: 'zipCode' },
        { text: this.$t('city'), value: 'city' },
        { text: this.$t('publicPlaceName'), value: 'publicPlaceNameNr' },
        { text: this.$t('primaryContactInformation'), value: 'billingName' },
        { text: '', value: 'btn' },
      ],
      desserts: [],
      addresses: [],
      loadingData: true,
      addressModal: {
        visible: false,
        addressItem: {},
        isEditable: false,
      },
      addressPrototype: {
        id: null,
        addressType: {
          id: 1,
          lang: 'hu',
          name: 'Szállítási cím',
        },
        billingName: '',
        countryCode: 'HUN',
        countryLabel: 'Magyarország',
        zipCode: '',
        city: '',
        publicPlaceName: '',
        publicPlaceType: '',
        houseNumber: '',
        otherAddressInfo: '',
        taxNumber: '',
      },
      selectFieldOptions: {
        postalCodes: [
          { header: this.$t('inputMessages.startTypingPostalCode') },
        ],
        cities: [
          { header: this.$t('inputMessages.startTypingCity') },
        ],
        streets: [
          { header: this.$t('inputMessages.startTypingStreet') },
        ],
      },
      inputRules: {
        generalRules: [v => !!v || this.$t('inputMessages.requiredField')],
        phoneRules: [
          v => /^[0-9\-]*$/.test(v) || this.$t('inputMessages.onlyNumbers'),
          v => !!v || this.$t('inputMessages.requiredField'),
        ],
        noSpecialChar: [
          (v) => {
            return /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.noSpecialChar')
          },
        ],
      },
      // szállítási cím
      deliveryInAddressString: '',
      deliveryInAddressForm: false,
      deliveryInAddressModal: false,
      countrySearch: '',
      countrySearchIsLoading: false,
      postalCodeSearch: '',
      postalCodeSearchIsLoading: false,
      citySearch: '',
      citySearchIsLoading: false,
      streetSearch: '',
      streetSearchIsLoading: false,
      streetIdeiglenes: '',
      deliveryInAddressBackup: null,
    };
  },
  methods: {
    loadTableData() {
      if (this.$store.state.authModule.user.addresses) {
        if (this.$store.state.authModule.user.addresses.length > 0) {
          this.$store.state.authModule.user.addresses.forEach(item => {
            if (item.addressType.id === 1) {
              if (item.billingName === "" ) {
                item.billingName = `${this.$store.state.authModule.user.profile.lastName} ${this.$store.state.authModule.user.profile.firstName}`;
              }
              item.publicPlaceNameNr = `${item.publicPlaceName} ${item.publicPlaceType} ${item.houseNumber}`;
              item.zipCode = item.zipCode.toString();
              this.addresses.push(item);
            }
          });
        } else {
          this.addresses = [];
        }
      } else {
        this.addresses = [];
      }
      this.loadingData = false;
    },
    deleteIdenticalAddresses(array) {
      const goodArray = array;
      // console.log('eredeti:');
      // console.table(goodArray);
      // console.log('START --------------');
      goodArray.sort((a, b) => ((a.isDefault === b.isDefault) ? 0 : a.isDefault ? -1 : 1));
      for (let a = 0; a < goodArray.length; a++) {
        const item = goodArray[a];
        // console.log(`${a}. item: `, item);
        if (item) {
          // console.log(`${a}. item.isDefault: `, item.isDefault);
          for (let b = a + 1; b < goodArray.length; b++) {
            const item2 = goodArray[b];
            // console.log(`${b}. item2: `, item2);
            if (item2) {
              // console.log(`${b}. item2.isDefault: `, item2.isDefault);
              if (item.billingName === item2.billingName
                && item.city === item2.city
                && item.houseNumber === item2.houseNumber
                && item.publicPlaceName === item2.publicPlaceName
                && item.publicPlaceType === item2.publicPlaceType
                && item.zipCode === item2.zipCode) {
                // console.log(`${a}. és ${b}. megegyezik - Törlés ${b}.`);
                delete goodArray[b];
              }
            }
          }
        }
      }
      // console.log('END --------------');
      const goodArrayFiltered = goodArray.filter((e) => e != null);
      // console.log('átalakított:');
      // console.table(goodArrayFiltered);
      // console.log('%cend delete', 'color: #FFFFFF; font-size: 12px; background: #6c5ce7;');
      return goodArrayFiltered;
    },
    openAddressModal(addressItem, isEditable = false) {
      this.addressModal.addressItem = addressItem;
      this.addressModal.isEditable = isEditable;
      this.addressModal.visible = true;
    },
    closeAddressModal() {
      this.addressModal.visible = false;
    },
    /**
     * SZÁLLÍTÁSI CÍM MEGADÁSÁHOZ FGV-EK
     */
    async getPostalCodesByCountry(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.addressPrototype.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodes = response.data;
        this.postalCodeSearchIsLoading = false;
        this.selectFieldOptions.postalCodes.unshift({ header: this.$t('inputMessages.startTypingPostalCode') });
      }
    },
    async getCityByPostalCode() {
      let postalcode = 0;
      if (this.addressPrototype.zipCode && this.addressPrototype.zipCode.address) {
        postalcode = this.addressPrototype.zipCode.address.postalCode;
        this.addressPrototype.zipCode = postalcode;
      }
      const response = await HEREGETCITYBYPOSTALCODE(this.addressPrototype.zipCode, this.addressPrototype.countryCode);
      if (!response.error) {
        this.selectFieldOptions.cities = response.data;
        this.citySearchIsLoading = false;
        this.selectFieldOptions.cities.unshift({ header: this.$t('inputMessages.startTypingCity') });
      }
    },
    async getStreetByPostalCode(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.addressPrototype.zipCode, this.addressPrototype.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streets = response.data;
        this.streetSearchIsLoading = false;
        this.selectFieldOptions.streets.unshift({ header: this.$t('inputMessages.startTypingStreet') });
      }
    },
    async onPostalCodeSelect() {
      await this.getCityByPostalCode();
      if (this.selectFieldOptions.cities.length === 2) {
        this.addressPrototype.city = this.selectFieldOptions.cities[1].address.city;
        this.citySearch = this.selectFieldOptions.cities[1].address.city;
      }
      this.addressPrototype.publicPlaceName = '';
      this.addressPrototype.publicPlaceType = '';
      this.addressPrototype.houseNumber = '';
      this.streetIdeiglenes = '';
      this.streetSearch = null;
    },
    onPublicPlaceSelect() {
      let street = '';
      let type = '';
      if (this.streetIdeiglenes && this.streetIdeiglenes.length > 0) {
        // console.log('1');
        street = this.streetIdeiglenes.substring(0, this.streetIdeiglenes.lastIndexOf(' '));
        type = this.streetIdeiglenes.substring(this.streetIdeiglenes.lastIndexOf(' '), this.streetIdeiglenes.length + 1).trim();
        if (this.streetIdeiglenes.lastIndexOf(' ') > 0) {
          // console.log('1a');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        } else {
          // console.log('1b');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = this.streetIdeiglenes;
          type = this.selectFieldOptions.streets[1].address.street.substring(this.selectFieldOptions.streets[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streets[1].address.street.length + 1).trim();
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        }
      } else if (this.streetIdeiglenes && this.streetIdeiglenes.address) {
        // console.log('2');
        street = this.streetIdeiglenes.address.street.substring(0, this.streetIdeiglenes.address.street.lastIndexOf(' '));
        type = this.streetIdeiglenes.address.street.substring(this.streetIdeiglenes.address.street.lastIndexOf(' '), this.streetIdeiglenes.address.street.length + 1).trim();
        if (this.streetIdeiglenes.address.street.lastIndexOf(' ') > 0) {
          // console.log('2a');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        } else {
          console.log('2b');
        }
      }
      //
      //
    },
    preventEnter() {
      //
    },
    clearAllAddressInputs() {
      this.selectFieldOptions.postalCodes = [
        { header: this.$t('inputMessages.startTypingPostalCode') },
      ];
      this.selectFieldOptions.cities = [
        { header: this.$t('inputMessages.startTypingCity') },
      ];
      this.selectFieldOptions.streets = [
        { header: this.$t('inputMessages.startTypingStreet') },
      ];
      this.postalCodeSearch = '';
      this.postalCodeSearchIsLoading = false;
      this.citySearch = '';
      this.citySearchIsLoading = false;
      this.streetSearch = '';
      this.streetSearchIsLoading = false;
      this.streetIdeiglenes = '';
      this.addressPrototype.zipCode = '';
      this.addressPrototype.city = '';
      this.addressPrototype.publicPlaceType = '';
      this.addressPrototype.publicPlaceName = '';
      this.addressPrototype.houseNumber = '';
    },
    async saveDeliveryInAddressData() {
      /* this.deliveryInAddressString = this.addressPrototype.zipCode + ' '
        + this.addressPrototype.city + ', '
        + this.addressPrototype.publicPlaceName + ' '
        + this.addressPrototype.publicPlaceType + ' '
        + this.addressPrototype.houseNumber;
      this.deliveryInAddressModal = false; */
      const response = this.$store.dispatch('', this.addressPrototype);

    },
  },
  watch: {
    postalCodeSearch(value) {
      this.postalCodeSearchIsLoading = true;
      this.getPostalCodesByCountry(value);
    },
    streetSearch(value) {
      this.streetSearchIsLoading = true;
      this.getStreetByPostalCode(value);
    },
  },
};
</script>

<style scoped>

</style>
