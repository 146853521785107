<template>
  <div v-frag>
    <v-card tile>
      <v-card-title class="third">
        <template v-if="address.addressType.id === 1">
          <v-icon color="white" class="mr-4">mdi-truck-fast-outline</v-icon>
          <span class="white--text">{{ $t('shippingAddress') }}</span>
        </template>
        <template v-if="address.addressType.id === 2">
          <v-icon color="white" class="mr-4">mdi-file-account-outline</v-icon>
          <span class="white--text">{{ $t('invoicingAddress') }}</span>
        </template>
        <v-spacer></v-spacer>
        <v-icon @click="close" color="white">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-6">
        <v-form v-model="addressForm">
          <v-row>
            <template v-if="address.addressType.id === 1">
              <v-col cols="12" sm="6">
                <v-text-field v-model="customerNameString"
                              :label="$t('primaryContactInformation')"
                              :rules="inputRules.generalRules"
                              :hint="$t('inputMessages.requiredField')"
                              :no-data-text="$t('inputMessages.noDataText')"
                              persistent-hint
                              filled
                              required
                              disabled>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
              </v-col>
            </template>
            <template v-if="address.addressType.id === 2">
              <v-col cols="12" sm="6">
                <v-text-field v-model="address.billingName"
                              :label="$t('invoicingName')"
                              :rules="inputRules.required"
                              :hint="$t('inputMessages.requiredField')"
                              persistent-hint
                              filled
                              :disabled="!isEditable">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="address.taxNumber"
                              :label="$t('vatNumber')"
                              :hint="$t('inputMessages.optionalField')"
                              persistent-hint
                              filled
                              :disabled="!isEditable">
                </v-text-field>
              </v-col>
            </template>
            <v-col cols="12" sm="6">
              <v-text-field v-model="address.zipCode"
                              :label="$t('inputLabels.postalCode')"
                              :rules="inputRules.phoneRules"
                              :hint="$t('inputMessages.requiredField')"
                              :no-data-text="$t('inputMessages.noDataText')"
                              persistent-hint
                              filled
                              maxlength="4"
                              required
                              @change="onPostalCodeSelect"
                              @click:clear="clearAllAddressInputs"
                              clearable
                              :disabled="!isEditable">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="address.city"
                              :label="$t('inputLabels.city')"
                              :rules="inputRules.generalRules"
                              :hint="$t('inputMessages.requiredField')"
                              :no-data-text="$t('inputMessages.noDataText')"
                              persistent-hint
                              filled
                              required
                              clearable
                              @click:clear="clearAllAddressInputs"
                              :disabled="!isEditable">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field v-model="streets"
                          :label="$t('inputLabels.publicPlaceName')"
                          :rules="inputRules.generalRules"
                          :hint="$t('inputMessages.requiredField')"
                          :no-data-text="$t('inputMessages.noDataText')"
                          persistent-hint
                          filled
                          required
                          @change="onPublicPlaceSelect"
                          clearable
                          :disabled="!isEditable">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="address.houseNumber"
                            :label="$t('inputLabels.streetNumber')"
                            :rules="inputRules.generalRules"
                            :hint="$t('inputMessages.requiredField')"
                            :no-data-text="$t('inputMessages.noDataText')"
                            persistent-hint
                            filled
                            required
                            clearable
                            :disabled="!isEditable">
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="address.otherAddressInfo"
                          :label="$t('infoToDeliver')"
                          :rules="inputRules.noSpecialChar"
                          :hint="$t('inputMessages.optionalField')"
                          persistent-hint
                          clearable
                          filled
                          clear-icon="mdi-close"
                          maxlength="50"
                          counter="50"
                          :disabled="!isEditable">
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="px-6 pb-6">
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn class="primary" v-show="isEditable" @click="saveCustomerNewAddress">
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- RESPONSE DIALOGS START -->
    <v-dialog v-model="loadingDialog.visible" width="90%" max-width="340" :persistent="true">
      <loading-dialog v-bind:loading-message="loadingDialog.message" />
    </v-dialog>
    <v-dialog v-model="responseDialog.visible" width="90%" max-width="340" :persistent="true">
      <response-dialog v-bind:response-object="responseDialog.response"
                       v-on:close-dialog="closeResponseDialog"/>
    </v-dialog>
  </div>
</template>

<script>
import {
  HEREGETPOSTALCODESBYCOUNTRY,
  HEREGETCITYBYPOSTALCODE,
  HEREGETSTREETBYPOSTALCODE
} from "../../utils/hereAPI";
import { getUid} from "../../utils/jwtHelper";
import LoadingDialog from "../dialogs/LoadingDialog.vue";
import ResponseDialog from "../dialogs/ResponseDialog.vue";

export default {
  name: 'AddressDetails',
  components: {
    LoadingDialog, ResponseDialog,
  },
  props: {
    address: {
      type: Object,
      default() {
        return {};
      }
    },
    isEditable: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      addressForm: false,
      selectFieldOptions: {
        postalCodes: [
          { header: this.$t('inputMessages.startTypingPostalCode') },
        ],
        cities: [
          { header: this.$t('inputMessages.startTypingCity') },
        ],
        streets: [
          { header: this.$t('inputMessages.startTypingStreet') },
        ],
      },
      inputRules: {
        generalRules: [v => !!v || this.$t('inputMessages.requiredField')],
        noSpecialChar: [
          (v) => {
            return /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.noSpecialChar')
          },
        ],
      },
      streets: this.address.publicPlaceName + ' ' + this.address.publicPlaceType,
      postalCodeSearch: '',
      postalCodeSearchIsLoading: false,
      citySearch: '',
      citySearchIsLoading: false,
      streetSearch: '',
      streetSearchIsLoading: false,
      streetIdeiglenes: '',
      customerNameString: '',
      loadingDialog: {
        visible: false,
        message: 'loadingMessages.INPROGRESS_CONTACT_FORM',
      },
      responseDialog: {
        visible: false,
        response: {
          error: false,
          data: [],
          message: 'successMessages.SUCCESS_REPAIR_FORM',
        },
      },
    };
  },
  async mounted() {
    this.customerNameString = `${this.$store.state.authModule.user.profile.lastName} ${this.$store.state.authModule.user.profile.firstName}`;
    if (this.isEditable) {
      this.postalCodeSearch = this.address.zipCode.toString();
      this.citySearch = this.address.city;
      this.streetSearch = `${this.address.publicPlaceName} ${this.address.publicPlaceType}`;
      this.streetIdeiglenes = `${this.address.publicPlaceName} ${this.address.publicPlaceType}`;
      await this.setCurrentCity();
    }
  },
  beforeUpdate() {
    this.streets = this.address.publicPlaceName + ' ' + this.address.publicPlaceType;
  },
  methods: {
    close() {
      this.$emit('close-dialog');
    },
    async saveCustomerNewAddress() {
      this.openLoadingDialog();
      const ui = await getUid();
      const response = await this.$store.dispatch('saveCustomerNewAddress', {form: this.address, cid: ui});
      // console.log('saveCustomerNewAddress response: ', response);
      if (response === 200) {
        this.responseDialog.response.message = `<div class="display-1 mb-6"><b>Sikeres beküldés!</b></div><div class="headline mb-4">Új cím hozzáadása sikeresen megtörtént.</div><div>Köszönjük megkeresését!</div>`;
      } else if (response === 400) {
        this.responseDialog.response.message = `<div class="display-1 mb-6"><b>204</b></div><div class="headline mb-4">Hiba</div><div>Köszönjük megkeresését!</div>`;
      } else if(response === 404) {
        this.responseDialog.response.message = '<div class="display-1 mb-6"><b>400 Váratlan hiba történt!</b></div><div class="headline mb-4">A folyamat során váratlan hiba lépett fel!<br>Dolgozunk a hiba elhárításán!</div><div>Köszönjük türelmét!</div>';
      } else if(response === 405) {
        this.responseDialog.response.message = '<div class="display-1 mb-6"><b>404 Váratlan hiba történt!</b></div><div class="headline mb-4">A folyamat során váratlan hiba lépett fel!<br>Dolgozunk a hiba elhárításán!</div><div>Köszönjük türelmét!</div>';
      } else {
        this.responseDialog.response.message = this.ERROR_MESSAGES.FORM_FAIL_MESSAGE;
      }
      this.controlDialogs();
    },
    openLoadingDialog() {
      this.loadingDialog.visible = true;
    },
    closeLoadingDialog() {
      this.loadingDialog.visible = false;
    },
    setupResponseDialog(message) {
      this.responseDialog.response.message = message;
    },
    openResponseDialog() {
      this.responseDialog.visible = true;
    },
    closeResponseDialog() {
      this.responseDialog.visible = false;
      this.$router.push({name: 'home'});
      window.location.reload();
    },
    controlDialogs() {
      setTimeout(this.closeLoadingDialog, 3000);
      setTimeout(this.openResponseDialog, 3200);
    },
    async getPostalCodesByCountry(value) {
      const response = await HEREGETPOSTALCODESBYCOUNTRY(value, this.address.countryCode);
      if (!response.error) {
        this.selectFieldOptions.postalCodes = response.data;
        this.postalCodeSearchIsLoading = false;
        this.selectFieldOptions.postalCodes.unshift({ header: this.$t('inputMessages.startTypingPostalCode') });
      }
    },
    async getCityByPostalCode() {
      let postalcode = 0;
      /* if (this.address.zipCode && this.address.zipCode.address) {
        postalcode = this.address.zipCode.address.postalCode;
        this.address.zipCode = postalcode;
      } */
      const response = await HEREGETCITYBYPOSTALCODE(this.address.zipCode, this.address.countryCode);
      if (!response.error) {
        this.selectFieldOptions.cities = response.data;
        this.citySearchIsLoading = false;
        this.selectFieldOptions.cities.unshift({ header: this.$t('inputMessages.startTypingCity') });
      }
    },
    async getStreetByPostalCode(value) {
      const response = await HEREGETSTREETBYPOSTALCODE(value, this.address.zipCode, this.address.countryCode);
      if (!response.error) {
        this.selectFieldOptions.streets = response.data;
        this.streetSearchIsLoading = false;
        this.selectFieldOptions.streets.unshift({ header: this.$t('inputMessages.startTypingStreet') });
      }
    },
    async onPostalCodeSelect() {
      await this.getCityByPostalCode();
      if (this.selectFieldOptions.cities.length === 2) {
        this.address.city = this.selectFieldOptions.cities[1].address.city;
        this.citySearch = this.selectFieldOptions.cities[1].address.city;
      }
      this.address.publicPlaceName = '';
      this.address.publicPlaceType = '';
      this.address.houseNumber = '';
      this.streetIdeiglenes = '';
      this.streetSearch = null;
    },
    async setCurrentCity() {
      await this.getCityByPostalCode();
      if (this.selectFieldOptions.cities.length === 2) {
        this.address.city = this.selectFieldOptions.cities[1].address.city;
        this.citySearch = this.selectFieldOptions.cities[1].address.city;
      }
    },
    onPublicPlaceSelect() {
      let street = '';
      let type = '';
      if (this.streetIdeiglenes && this.streetIdeiglenes.length > 0) {
        // console.log('1');
        street = this.streetIdeiglenes.substring(0, this.streetIdeiglenes.lastIndexOf(' '));
        type = this.streetIdeiglenes.substring(this.streetIdeiglenes.lastIndexOf(' '), this.streetIdeiglenes.length + 1).trim();
        if (this.streetIdeiglenes.lastIndexOf(' ') > 0) {
          // console.log('1a');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        } else {
          // console.log('1b');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = this.streetIdeiglenes;
          type = this.selectFieldOptions.streets[1].address.street.substring(this.selectFieldOptions.streets[1].address.street.lastIndexOf(' '), this.selectFieldOptions.streets[1].address.street.length + 1).trim();
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        }
      } else if (this.streetIdeiglenes && this.streetIdeiglenes.address) {
        // console.log('2');
        street = this.streetIdeiglenes.address.street.substring(0, this.streetIdeiglenes.address.street.lastIndexOf(' '));
        type = this.streetIdeiglenes.address.street.substring(this.streetIdeiglenes.address.street.lastIndexOf(' '), this.streetIdeiglenes.address.street.length + 1).trim();
        if (this.streetIdeiglenes.address.street.lastIndexOf(' ') > 0) {
          // console.log('2a');
          this.serviceRequestForm.deliveryInAddress.publicPlaceName = street;
          this.serviceRequestForm.deliveryInAddress.publicPlaceType = type;
        } else {
          console.log('2b');
        }
      }
      //
      //
    },
    preventEnter() {
      //
    },
    clearAllAddressInputs() {
      this.selectFieldOptions.postalCodes = [
        { header: this.$t('inputMessages.startTypingPostalCode') },
      ];
      this.selectFieldOptions.cities = [
        { header: this.$t('inputMessages.startTypingCity') },
      ];
      this.selectFieldOptions.streets = [
        { header: this.$t('inputMessages.startTypingStreet') },
      ];
      this.postalCodeSearch = '';
      this.postalCodeSearchIsLoading = false;
      this.citySearch = '';
      this.citySearchIsLoading = false;
      this.streetSearch = '';
      this.streetSearchIsLoading = false;
      this.streetIdeiglenes = '';
      this.address.postalCode = '';
      this.address.city = '';
      this.address.publicPlaceType = '';
      this.address.publicPlaceName = '';
      this.address.houseNumber = '';
    },
    saveDeliveryInAddressData() {
      //
    },
  },
  watch: {
    postalCodeSearch(value) {
      this.postalCodeSearchIsLoading = true;
      this.getPostalCodesByCountry(value);
    },
    streetSearch(value) {
      this.streetSearchIsLoading = true;
      this.getStreetByPostalCode(value);
    },
  },
};
</script>

<style scoped>

</style>
